import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import header from '../components/part/Header'
import FormScreen from '../screens/app/Team/FormScreen'
import ListScreen from '../screens/app/Team/ListScreen'
import ViewScreen from '../screens/app/Team/ViewScreen'

const Stack = createStackNavigator()

const TeamNavigator = () => (
  <Stack.Navigator initialRouteName="Team_List">
    <Stack.Screen
      name="Team_Create"
      component={FormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Team_Form"
      component={FormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Team_View"
      component={ViewScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Team_List"
      component={ListScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showBackButton: false,
            showNudgifications: false,
          }),
        }
      }}
    />
  </Stack.Navigator>
)

export default TeamNavigator
