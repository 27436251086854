import { useQuery } from 'react-query'

import { get, post, patch, remove } from '../../utility/api'
import { ITeam } from '../../@types/TeamItem'
import { QUERY_KEYS } from '../../config/queryKeys'
export const endpoint = '/teams'

const getTeams = (userId?: string) => {
  return useQuery<ITeam[]>('teams', ({ signal }) =>
    get('/users/' + userId + endpoint + '?order[name]=asc', {
      signal,
    })
  )
}

const getTeam = (teamId?: string | undefined | null) => {
  return useQuery<ITeam>(
    QUERY_KEYS.team.item(teamId),
    ({ signal }) =>
      get(`${endpoint}/${teamId}`, {
        signal,
      }),
    {
      enabled: undefined !== teamId && teamId !== null,
    }
  )
}

const deleteTeam = (id) => {
  return remove(endpoint + '/' + id)
}

export const addTeam = (team: ITeam) => {
  team.users = team.users.map((member) => {
    return '/users/' + member.id
  })

  return post(endpoint, team)
}

export const updateTeam = (team: ITeam) => {
  team.users = team.users.map((member) => {
    return '/users/' + member.id
  })

  return patch(endpoint + '/' + team.id, team, {
    headers: { 'content-type': 'application/merge-patch+json' },
  })
}

export default {
  addTeam,
  updateTeam,
  deleteTeam,
  getTeams,
  getTeam,
}
