import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { StyleSheet, View } from 'react-native'

import AppText from '../Text'
import { ErrorMessage, PickerEmail } from '../forms'

function TeamEmailList({ field }) {
  const { values } = useFormikContext()
  console.log(values)
  return (
    <View>
      <AppText style={styles.heading}>Team Emails</AppText>
      {values[field] && values[field].length === 0 && (
        <AppText>No email addresses</AppText>
      )}

      <FieldArray
        name={field}
        render={(arrayHelpers) => (
          <div>
            {arrayHelpers.form.values[field]?.map((item, index) => {
              return (
                <div key={index}>
                  <button
                    type="button"
                    onClick={() => {
                      arrayHelpers.remove(index)
                    }}
                    style={{
                      borderWidth: 0,
                      fontWeight: 600,
                      position: 'absolute',
                      marginTop: 15,
                      right: 15,
                      backgroundColor: '#ddd',
                      color: '#777',
                      borderRadius: 10,
                      width: 20,
                      height: 20,
                      fontSize: 16,
                      zIndex: 10,
                    }}
                  >
                    -
                  </button>
                  <PickerEmail name={`${field}.${index}`} />
                </div>
              )
            })}

            <button
              type="button"
              onClick={() => {
                arrayHelpers.push('')
              }}
              style={{
                backgroundColor: '#ffffff',
                borderWidth: 0,
                fontFamily: 'Open Sans',
                fontSize: 16,
                fontWeight: 600,
                color: '#3EACBE',
                marginTop: 20,
                width: '100%',
              }}
            >
              + Add another item
            </button>

            <ErrorMessage
              error={arrayHelpers.form.errors[field]}
              visible={arrayHelpers.form.touched[field]}
            />
          </div>
        )}
      />
    </View>
  )
}

export default TeamEmailList

const styles = StyleSheet.create({
  heading: {
    fontWeight: '600',
    minWidth: 150,
    marginBottom: 10,
    marginTop: 20,
  },
})
