import React, { useRef, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'

import { Form, FormField, SubmitButton } from '../../../components/forms'

import Screen from '../../../components/Screen'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'

import TitleGraphic from '../../../components/TitleGraphic'
import teamQuery from '../../../api/query/team'
import FormTeamPicker from '../../../components/forms/FormTeamPicker'
import TeamEmailList from '../../../components/picker/TeamEmailList'
import AppButton from '../../../components/Button'
import ActivityIndicator from '../../../components/ActivityIndicator'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { ITeam } from '../../../@types/TeamItem'
import { FormikProps } from 'formik'

const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(1).max(40).label('Name'),
  users: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().matches(
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-6][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
          {
            name: 'uuid',
            excludeEmptyString: false,
          }
        ),
        name: Yup.string().required(),
      })
    )
    .label('Members'),
  emails: Yup.array().of(Yup.string().email()).label('Members'),
})

export default function FormScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const queryClient = useQueryClient()
  const formikRef = useRef<FormikProps<ITeam>>(null)
  const teamId: string | null = route.params?.teamId ?? null
  const duplicate: boolean = route.params?.duplicate ?? false
  const { isLoading, isSuccess, isError, data, refetch } =
    teamQuery.getTeam(teamId)

  if (duplicate && data) {
    data.id = null
  }
  console.log('data', data)
  const defaultTeam: ITeam = {
    id: null,
    name: '',
    users: [],
    emails: [],
  }

  const [initialValues, setInitialValues] = useState<ITeam>(data ?? defaultTeam)

  useFocusEffect(
    React.useCallback(() => {
      setInitialValues(data ?? defaultTeam)

      if (formikRef.current) {
        console.log('form reset')
        formikRef.current?.resetForm()
        formikRef.current?.setValues(initialValues)
      }
    }, [route.params, data])
  )

  function redirectList() {
    navigation.navigate('TEAM', {
      screen: 'Team_List',
    })
  }

  const teamUpdateMutation = useMutation(teamQuery.updateTeam, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['team', { teamId: data.id }])
      queryClient.invalidateQueries(['teams'])

      setInitialValues({
        // ...newTeam,
        ...data,
      })

      redirectList()
    },
    onError: (error) => {
      alert('Failed to update your Team')
      console.log(error)
    },
  })

  const teamAddMutation = useMutation(teamQuery.addTeam, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['team', { teamId: data.id }])
      queryClient.invalidateQueries(['teams'])

      setInitialValues({
        // ...newTeam,
        ...data,
      })

      redirectList()
    },
    onError: (error) => {
      alert('Failed to add your Team')
      console.log(error)
    },
  })

  const handleSubmit = async (newTeam: ITeam) => {
    newTeam = {
      ...data,
      ...newTeam,
    }

    console.log('handleSubmit', newTeam, newTeam.id, data)

    if (newTeam.id) {
      teamUpdateMutation.mutate(newTeam)
    } else {
      teamAddMutation.mutate(newTeam)
    }
  }

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="TEAM"
        title="Create TEAM"
        graphic={require('../../../assets/lead-rapid-review.jpg')}
      />

      <View style={defaultStyles.mainContainer}>
        {isLoading && <ActivityIndicator visible={isLoading} />}
        {isError && (
          <>
            <AppText>Couldn't retrieve the Team.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {(isSuccess || teamId === null) && (
          <Form
            innerRef={formikRef}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
          >
            <View style={styles.table}>
              <View style={[styles.rowBorder]}>
                <AppText style={styles.heading}>Team</AppText>
                <View style={styles.field}>
                  <FormField
                    maxLength={40}
                    name="name"
                    placeholder="What should we call this team?"
                  />
                </View>
              </View>
              <View style={[styles.rowBorder]}>
                <AppText style={styles.heading}>Team members</AppText>
                <FormTeamPicker
                  field="users"
                  placeholder="Build your team"
                  group={true}
                  width="100%"
                />
              </View>

              <View style={[styles.rowBorder]}>
                <AppText style={styles.heading}>Email addresses</AppText>
                <AppText>
                  For a team member that does not have an account, but you'd
                  still like them to recieve notifications.
                </AppText>
                <TeamEmailList field="emails" />
              </View>

              <SubmitButton title={data?.id ? 'Save' : 'Create'} />
            </View>
          </Form>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: '700',
    minWidth: 150,
    marginBottom: 10,
  },
  icon: {
    fontWeight: '700',
    width: 40,
    height: 40,
    lineHeight: 40,
    borderRadius: 40,
    backgroundColor: colors.primary,
    color: '#fff',
    textAlign: 'center',
    marginRight: 10,
  },
  field: {},
  title: {
    fontWeight: '700',
    height: 40,
    lineHeight: 40,
  },
  table: {},
  row: {
    flexDirection: 'row',
    paddingVertical: 20,
  },
  rowBorder: {
    borderBottomColor: colors.light,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: 20,
  },
  horizontalAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
