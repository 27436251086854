import React from 'react'
import { Formik, FormikProps } from 'formik'

interface AppFormProps {
  initialValues: any
  onSubmit: (values: any) => void
  validationSchema: any
  innerRef?: React.RefObject<FormikProps<any>>
  enableReinitialize?: boolean
  children: React.ReactNode
}

const AppForm: React.FC<AppFormProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  innerRef = null,
  enableReinitialize = false,
  children,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={innerRef}
      enableReinitialize={enableReinitialize}
    >
      {() => <>{children}</>}
    </Formik>
  )
}

export default AppForm
