import { LinkingOptions } from '@react-navigation/native'

type RootStackParamList = {
  // CriticalImpactOpportunity_EditForm: undefined
}

export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [
    '/',
    // 'https://app.coachingimpact.com',
    // 'http://app.comparisontechnology.com:19006',
  ],
  config: {
    /* configuration for matching screens with paths */
    screens: {
      Login: 'login',
      Welcome: '',
      BOOM: {
        screens: {
          Boom: 'boom',
          Boom_Intro: 'boom/intro',
          Boom_Edit: 'boom/:boomId/edit',
          Boom_Share: 'boom/:boomId/share',
        },
      },
      BOSS: {
        screens: {
          Boss_List: 'boss/list',
          Boss_View: 'boss/:bossId',
          Boss_Form: 'boss/form/:bossPracticeId',
          Boss_AddForm: 'boss/form',
        },
      },
      CRITICAL_IMPACT_OPPORTUNITY: {
        screens: {
          CriticalImpactOpportunity_Intro: 'critical-impact-opportunity/intro',
          CriticalImpactOpportunity_List: 'critical-impact-opportunity/list',
          CriticalImpactOpportunity_List_Archived:
            'critical-impact-opportunity/archived',
          CriticalImpactOpportunity_AddForm: 'critical-impact-opportunity/add',
          CriticalImpactOpportunity_AddForm_Pick:
            'critical-impact-opportunity/add/pick',
          CriticalImpactOpportunity_Menu:
            'critical-impact-opportunity/:criticalImpactOpportunityId',
          CriticalImpactOpportunity_View:
            'critical-impact-opportunity/:criticalImpactOpportunityId/view',
          CriticalImpactOpportunity_Form:
            'critical-impact-opportunity/:criticalImpactOpportunityId/edit',
          CriticalImpactOpportunity_Form_Pick:
            'critical-impact-opportunity/:criticalImpactOpportunityId/edit/pick',
          CriticalImpactOpportunity_Form_Prep:
            'critical-impact-opportunity/:criticalImpactOpportunityId/edit/prep',
          CriticalImpactOpportunity_Form_Perform:
            'critical-impact-opportunity/:criticalImpactOpportunityId/edit/perform',
          CriticalImpactOpportunity_Form_Pause:
            'critical-impact-opportunity/:criticalImpactOpportunityId/edit/pause',
          CriticalImpactOpportunity_Form_Pause_RapidReview:
            'critical-impact-opportunity/:criticalImpactOpportunityId/edit/rapid-review',
          CriticalImpactOpportunity_Done:
            'critical-impact-opportunity/:criticalImpactOpportunityId/done',
        },
      },
      DASHBOARD: {
        screens: {
          Dashboard_Screen: 'dashboard',
          ShedRatingScreen: 'shed/rate',
          ShedProgressScreen: 'shed/progress',
          ShedTipsScreen: 'shed/tips',
          Nudgification_Screen: 'nudgifications',
        },
      },
      HELP: {
        screens: {
          FAQ_Screen: 'help/faq',
          FAQ_View: 'help/faq/:faqId',
          Message_Screen: 'help/message',
        },
      },
      PROFILE: {
        screens: {
          Profile: 'profile',
          Profile_Form: 'profile/edit',
          Profile_WelcomeScreen: 'profile/welcome-message',
          Profile_Ritual: 'profile/ritual',
          Profile_PreRitual: 'profile/pre-ritual',
          Profile_Nudge: 'profile/nudge',
        },
      },
      RAPID_REVIEW: {
        screens: {
          RapidReview_Hub: 'rapid-reviews',
          RapidReview_List: 'rapid-reviews/personal',
          RapidReview_Form: 'rapid-review/personal/add',
          RapidReview_Form_With_Meeting:
            'rapid-review/personal/add/cio/:meetingId',
          RapidReview_Form_With_CIO:
            'rapid-review/personal/add/meeting/:criticalImpactOpportunityId',
          RapidReview_View: 'rapid-review/personal/:rapidReviewId',
          RapidReview_Done: 'rapid-review/personal/done',
          Meeting_List: 'rapid-review/team',
          Meeting_Form: 'rapid-review/team/edit',
          Meeting_View_Upcoming: 'rapid-review/team/upcoming/:meetingId',
          Meeting_View_InProgress: 'rapid-review/team/in-progress/:meetingId',
          Meeting_View_Past: 'rapid-review/team/past/:meetingId',
          Meeting_RapidReview_View: 'rapid-review/team/view',
          Meeting_End: 'rapid-review/team/:meetingId/end',
          Meeting_Done: 'rapid-review/team/done',
          Meeting_Saved: 'rapid-review/team/saved',
          Meeting_Join: 'rapid-review/team/join',
          Meeting_Camera: 'rapid-review/team/camera',
        },
      },
      REMINDER: {
        screens: {
          Reminder_Screen: 'nudges',
        },
      },
      RESOURCE: {
        screens: {
          Resource_List: 'resources',
          Resource_View: 'resources/:slug',
        },
      },
      SHARED_WITH_ME: {
        screens: {
          SharedWithMe_List: 'shared-with-me',
          SharedWithMe_Boom_List: 'shared-with-me/booms',
          SharedWithMe_Boom_View: 'shared-with-me/boom/:boomId',
          SharedWithMe_CriticalImpactOpportunity_List:
            'shared-with-me/critical-impact-opportunity',
          SharedWithMe_CriticalImpactOpportunity_Overview:
            'shared-with-me/critical-impact-opportunity/:criticalImpactOpportunityId/overview',
          SharedWithMe_CriticalImpactOpportunity_View:
            'shared-with-me/critical-impact-opportunity/:criticalImpactOpportunityId/view',
          SharedWithMe_RapidReview_List: 'shared-with-me/rapid-review',
          SharedWithMe_RapidReview_View:
            'shared-with-me/rapid-review/:rapidReviewId',
        },
      },
      TEAM: {
        screens: {
          Team_Create: 'teams/add',
          Team_List: 'teams',
          Team_Form: 'teams/:teamId/edit',
          Team_View: 'teams/:teamId',
        },
      },
      TROPHY_CABINET: {
        screens: {
          Trophy_List: 'trophies',
          Trophy_View: 'trophies/:trophyId',
          Trophy_Form: 'trophies/:trophyId/edit',
        },
      },
    },
  },
}
