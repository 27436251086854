import React, { useState } from 'react'
import { View } from 'react-native'
import { useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'

import Screen from '../../../components/Screen'
import criticalImpactOpportunityQuery from '../../../api/query/criticalImpactOpportunity'

import { defaultStyles } from '../../../config/styles'
import TitleGraphic from '../../../components/TitleGraphic'
import { ICio } from 'types/cio'
import AppText from '../../../components/Text'
import ActivityIndicator from '../../../components/ActivityIndicator'
import AppButton from '../../../components/Button'
import { FormikStep } from '../../../components/formik/FormikStep'
import CioTitle from '../../../components/cio/CioTitle'
import { FormDatePicker, FormField } from '../../../components/forms'
import {
  FormNudge,
  NudgeValidationSchema,
} from '../../../components/formik/FormNudge'
import { FormikStepper } from '../../../components/formik/FormikStepper'
import { Sections } from '../../../utility/cioSection'
import IsNotificationDisabled from '../../../components/IsNotificationDisabled'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'

export default function FormPickScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const cioId: string | null = route.params?.criticalImpactOpportunityId ?? null
  const { isLoading, isSuccess, isError, data, refetch } =
    criticalImpactOpportunityQuery.getCriticalImpactOpportunity(cioId)

  const queryClient = useQueryClient()
  const [step, setStep] = useState(0)

  const cioUpdateMutation = useMutation(
    criticalImpactOpportunityQuery.updateCriticalImpactOpportunity,
    {
      onSuccess: (data: ICio) => {
        queryClient.invalidateQueries('cios')
        queryClient.invalidateQueries([
          'cio',
          { criticalImpactOpportunityId: data.id },
        ])

        navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
          screen: 'CriticalImpactOpportunity_Form',
          params: {
            criticalImpactOpportunityId: data.id,
          },
        })
      },
      onError: (error) => {
        alert('Failed to update the CIO')
        console.log(error)
      },
    }
  )

  const cioAddMutation = useMutation(
    criticalImpactOpportunityQuery.addCriticalImpactOpportunity,
    {
      onSuccess: (data) => {
        console.log(data)
        queryClient.invalidateQueries('cios')
        navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
          screen: 'CriticalImpactOpportunity_Form',
          params: {
            criticalImpactOpportunityId: data.id,
          },
        })
      },
      onError: (error) => {
        alert('Failed to save the CIO')
        console.log(error)
      },
    }
  )

  if (isSuccess && typeof data.date === 'undefined') {
    data.date = Date()
  }

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Critical Impact Opportunities"
        title="Create a CIO"
        graphic={require('../../../assets/lead-cio.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        {isLoading && <ActivityIndicator visible={isLoading} />}
        {isError && (
          <>
            <AppText>Couldn't retrieve the CIO.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <>
            <FormikStepper
              initialValues={data}
              onSubmit={(newCio, { resetForm }) => {
                newCio.section = Sections.pick

                if (cioId) {
                  cioUpdateMutation.mutate(newCio, {
                    onSuccess: () => {
                      resetForm()
                    },
                  })
                } else {
                  cioAddMutation.mutate(newCio, {
                    onSuccess: () => {
                      resetForm()
                    },
                  })
                }
              }}
              allowSkip={false}
              step={step}
              setStep={setStep}
            >
              <FormikStep
                validationSchema={Yup.object({
                  what: Yup.string()
                    .required()
                    .min(1)
                    .label('What is your CIO'),
                  date: Yup.date().required().label('When'),
                  location: Yup.string().required().min(1).label('Where'),
                })}
              >
                <CioTitle title="Pick" />
                <View style={defaultStyles.mainContainer}>
                  <AppText style={defaultStyles.questionWithoutPadding}>
                    What is your CIO?
                  </AppText>
                  <FormField
                    multiline
                    name="what"
                    numberOfLines={3}
                    placeholder="Give your CIO a title"
                  />

                  <AppText
                    style={[
                      defaultStyles.questionWithoutPadding,
                      { marginTop: 20 },
                    ]}
                  >
                    When is it?
                  </AppText>
                  <FormDatePicker
                    name="date"
                    icon="calendar-month"
                    dateFormat="do MMMM yyyy"
                    placeholder="Date picker"
                  />

                  <AppText
                    style={[
                      defaultStyles.questionWithoutPadding,
                      { marginTop: 20 },
                    ]}
                  >
                    Where is it?
                  </AppText>
                  <FormField
                    multiline
                    name="location"
                    numberOfLines={3}
                    placeholder=""
                  />
                </View>
              </FormikStep>
              <FormikStep
                validationSchema={Yup.object({
                  why: Yup.string().required().min(1).label('Why'),
                })}
              >
                <CioTitle title="Pick" />
                <View style={defaultStyles.mainContainer}>
                  <AppText style={defaultStyles.questionWithoutPadding}>
                    Why is it worth your extra effort?
                  </AppText>
                  <FormField
                    multiline
                    name="why"
                    numberOfLines={3}
                    placeholder="What would be the key benefits?"
                  />
                </View>
              </FormikStep>
            </FormikStepper>
          </>
        )}
      </View>
    </Screen>
  )
}
