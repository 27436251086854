import React from 'react'
import { View } from 'react-native'

import colors from '../../config/colors'
import { defaultStyles } from '../../config/styles'
import AppText from '../Text'
import Panel from './Panel'
import Icon from '../Icon'
import { Sections, SectionOrder } from '../../utility/cioSection'
import { useNavigation } from '@react-navigation/native'
import criticalImpactOpportunityQuery from '../../api/query/criticalImpactOpportunity'
import ActivityIndicator from '../../components/ActivityIndicator'
import AppButton from '../../components/Button'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../navigation/AppNavigator'

interface IMain {
  nextSection: Sections
  cioId: string | null
}

function Main({ nextSection, cioId }: IMain) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { isLoading, isSuccess, isError, data, refetch } =
    criticalImpactOpportunityQuery.getCriticalImpactOpportunity(cioId)

  console.log(cioId, data)
  let prepEnabled = false
  let performEnabled = false
  let pauseEnabled = false
  let section: Sections = Sections.pick

  if (Sections.prep === nextSection) {
    prepEnabled = true
    section = Sections.prep
  } else if (Sections.perform === nextSection) {
    prepEnabled = true
    performEnabled = true
    section = Sections.perform
  } else if (Sections.pause <= nextSection) {
    prepEnabled = true
    performEnabled = true
    pauseEnabled = true
    section = Sections.pause
  }

  return (
    <>
      <SectionHeader section={section} />
      <br />

      <View>
        {isLoading && <ActivityIndicator visible={isLoading} />}
        {isError && (
          <>
            <AppText>Couldn't retrieve the CIO.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <>
            <View style={{ flexDirection: 'row', marginBottom: 20 }}>
              <Panel
                text="PICK"
                section={Sections.pick}
                enabled={true}
                extraStyles={{ marginRight: 10 }}
                onPress={() => {
                  console.log('PICK')
                  if (data.id) {
                    navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                      screen: 'CriticalImpactOpportunity_Form_Pick',
                      params: {
                        criticalImpactOpportunityId: data.id,
                      },
                    })
                  } else {
                    navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                      screen: 'CriticalImpactOpportunity_AddForm_Pick',
                    })
                  }
                }}
              />
              <Panel
                text="PREP"
                section={Sections.prep}
                enabled={prepEnabled}
                extraStyles={{ marginLeft: 10 }}
                onPress={() => {
                  console.log('PREP')
                  navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                    screen: 'CriticalImpactOpportunity_Form_Prep',
                    params: {
                      criticalImpactOpportunityId: data.id,
                    },
                  })
                }}
              />
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Panel
                text="PERFORM"
                section={Sections.perform}
                enabled={performEnabled}
                extraStyles={{ marginRight: 10 }}
                onPress={() => {
                  console.log('PERFORM')
                  navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                    screen: 'CriticalImpactOpportunity_Form_Perform',
                    params: {
                      criticalImpactOpportunityId: data.id,
                    },
                  })
                }}
              />
              <Panel
                text="PAUSE"
                section={Sections.pause}
                enabled={pauseEnabled}
                extraStyles={{ marginLeft: 10 }}
                onPress={() => {
                  console.log('PAUSE')

                  navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                    screen: 'CriticalImpactOpportunity_Form_Pause',
                    params: {
                      criticalImpactOpportunityId: data.id,
                    },
                  })
                }}
              />
            </View>
          </>
        )}
      </View>
    </>
  )
}

interface ISectionText {
  section: Sections
}

function SectionText({ section }: ISectionText) {
  let text = "Let's start with"
  let nextSection: string = ''

  if (Sections.prep === section) {
    text = "Let's keep going with"
    nextSection = 'Prep'
  } else if (Sections.perform === section) {
    text =
      'The third stage is aimed at enabling you to be at your best on the day so click below when you’re preparing to'
    nextSection = 'Perform'
  } else if (Sections.pause === section) {
    text = "Let's finish with"
    nextSection = 'Pause'
  } else {
    nextSection = 'Pick'
  }

  return (
    <AppText>
      {text}{' '}
      <span
        style={{
          color: colors.primary,
          textTransform: 'uppercase',
          fontWeight: 'bold',
        }}
      >
        {nextSection}
      </span>
    </AppText>
  )
}

interface ISectionHeader {
  section: Sections
}

function SectionHeader({ section }: ISectionHeader) {
  if (Sections.prep === section) {
    return (
      <View style={defaultStyles.lightBox}>
        <View style={{ alignItems: 'center' }}>
          <Icon name="check-circle" size={60} iconColor={colors.primary} />
        </View>
        <br />
        <AppText>Well done. You’ve picked your moment that matters.</AppText>
        <br />
        <AppText>
          Now, in order to create the clarity and energy you need to get the
          most out of that moment, you need to fill out the second P – Prepare.
          If you can’t complete all the Preparation stages now, skip when you
          need to, but be sure to save it and return later to complete your
          critical preparation.
        </AppText>
        <br />
        <SectionText section={section} />
      </View>
    )
  } else if (Sections.perform === section) {
    return (
      <View style={defaultStyles.lightBox}>
        <View style={{ alignItems: 'center' }}>
          <Icon name="check-circle" size={60} iconColor={colors.primary} />
        </View>
        <br />
        <AppText>This is the final part of your preparation.</AppText>
        <br />
        <AppText>
          For you to perform at your best in your CIO, you need to set out your
          conditions for success and how to create those conditions so you can
          be at your best. Set out the conditions here and the HabitBuilder can
          nudge you towards that success.
        </AppText>
        <br />
        <SectionText section={section} />
      </View>
    )
  } else if (Sections.pause === section) {
    return (
      <View style={defaultStyles.lightBox}>
        <View style={{ alignItems: 'center' }}>
          <Icon name="check-circle" size={60} iconColor={colors.primary} />
        </View>
        <br />
        <AppText>Well done. Your CIO is done.</AppText>
        <br />
        <AppText>
          In order to learn from it and build for your next CIO, take the time
          to PAUSE and reflect on how it went – either by yourself, with
          colleagues or even with your audience. When you’re ready…
        </AppText>
        <br />
        <SectionText section={section} />
      </View>
    )
  }

  return (
    <>
      <AppText>
        It is very hard to give 100% all the time. Use this opportunity to
        choose which interactions in the next few weeks/months matter most and
        prepare for them.
      </AppText>
      <br />
      <AppText>
        If you have events that you want to put real, deliberate effort into,
        you need to create a CIO. To give you the best possible chance to make
        the most of each CIO, we will now start going through the 4Ps Process
        with you.
      </AppText>
      <br />
      <SectionText section={section} />
    </>
  )
}

export default Main
