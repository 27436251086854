import React from 'react'
import { Alert, Platform, StyleSheet, View } from 'react-native'
import { useMutation, useQueryClient } from 'react-query'

import TitleGraphic from '../../../components/TitleGraphic'
import Screen from '../../../components/Screen'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import Button from '../../../components/Button'
import teamQuery from '../../../api/query/team'
import ActivityIndicator from '../../../components/ActivityIndicator'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

export default function ViewScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const queryClient = useQueryClient()
  const teamId: string | null = route.params?.teamId ?? null
  const { isLoading, isSuccess, isError, data, refetch } =
    teamQuery.getTeam(teamId)

  const teamDeleteMutation = useMutation(teamQuery.deleteTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries(['team', { teamId: teamId }])
      queryClient.invalidateQueries(['teams'])

      navigation.navigate('TEAM', {
        screen: 'Team_List',
      })
    },
    onError: (error) => {
      alert('Failed to delete your Team')
      console.log(error)
    },
  })

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="TEAM"
        title="View TEAM"
        graphic={require('../../../assets/lead-rapid-review.jpg')}
      />

      <View style={defaultStyles.mainContainer}>
        {isLoading && <ActivityIndicator visible={isLoading} />}
        {isError && (
          <>
            <AppText>Couldn't retrieve the data.</AppText>
            <Button title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <>
            <View style={styles.table}>
              <View style={[styles.rowBorder]}>
                <AppText style={styles.heading}>Team Name: </AppText>
                <AppText>{data.name}</AppText>
              </View>
              <View style={[styles.rowBorder]}>
                <AppText style={styles.heading}>Team Members: </AppText>
                {data.users.length > 0 &&
                  data.users.map((member) => (
                    <AppText key={member.id}>{member.name}</AppText>
                  ))}
                {data.users.length === 0 && <AppText>No team members</AppText>}
              </View>
              <View style={[styles.rowBorder]}>
                <AppText style={styles.heading}>Team Emails: </AppText>
                {data.emails.length > 0 &&
                  data.emails.map((email, index) => (
                    <AppText key={'email_' + index}>{email}</AppText>
                  ))}
                {data.emails.length === 0 && <AppText>No emails</AppText>}
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.button}>
                <Button
                  title="Edit"
                  onPress={() => {
                    navigation.navigate('TEAM', {
                      screen: 'Team_Form',
                      params: {
                        teamId: data.id,
                      },
                    })
                  }}
                />
              </View>
              <View style={styles.button}>
                <Button
                  title="Duplicate"
                  onPress={() => {
                    navigation.navigate('TEAM', {
                      screen: 'Team_Form',
                      params: {
                        teamId: data.id,
                        duplicate: true,
                      },
                    })
                  }}
                />
              </View>
              <View style={styles.button}>
                <Button
                  title="Delete"
                  onPress={() => {
                    if (Platform.OS === 'web') {
                      if (
                        confirm('Are you sure you want to delete this TEAM?')
                      ) {
                        teamDeleteMutation.mutate(data.id)
                      }
                    } else {
                      Alert.alert(
                        'Delete',
                        'Are you sure you want to delete this TEAM?',
                        [
                          {
                            text: 'Yes',
                            style: 'destructive',
                            onPress: () => {
                              teamDeleteMutation.mutate(data.id)
                            },
                          },
                          { text: 'No', style: 'cancel' },
                        ]
                      )
                    }
                  }}
                />
              </View>
            </View>
          </>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  button: {
    width: '30%',
    marginRight: 10,
  },
  heading: {
    fontWeight: '700',
    minWidth: 150,
    marginBottom: 10,
  },
  icon: {
    fontWeight: '700',
    width: 40,
    height: 40,
    lineHeight: 40,
    borderRadius: 40,
    backgroundColor: colors.primary,
    color: '#fff',
    textAlign: 'center',
    marginRight: 10,
  },
  title: {
    fontWeight: '700',
    height: 40,
    lineHeight: 40,
  },
  table: {},
  row: {
    flexDirection: 'row',
    paddingVertical: 20,
  },
  rowBorder: {
    borderBottomColor: colors.light,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: 20,
  },
  horizontalAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  teamValue: {
    marginTop: 10,
  },
})
